(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-easy-swipe"));
	else if(typeof define === 'function' && define.amd)
		define("cheeseburger-menu", ["React", "react-easy-swipe"], factory);
	else if(typeof exports === 'object')
		exports["cheeseburger-menu"] = factory(require("react"), require("react-easy-swipe"));
	else
		root["cheeseburger-menu"] = factory(root["React"], root["react-easy-swipe"]);
})(typeof self !== 'undefined' ? self : this, function(__WEBPACK_EXTERNAL_MODULE__639__, __WEBPACK_EXTERNAL_MODULE__7__) {
return 